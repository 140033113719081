export const countries = {
  '/en-ca': {
    label: 'Canada',
    language: 'EN',
    country: 'CA',
    currency: 'CAD',
  },
  '/en-de': {
    label: 'European Union',
    language: 'EN',
    country: 'DE',
    currency: 'EUR',
  },
  '/en-am': {
    label: 'Middle East',
    language: 'EN',
    country: 'AM',
    currency: 'USD',
  },
  '#': {
    label: 'Russian Federation',
    language: 'EN',
    country: 'RU',
    currency: 'EUR',
  },
  '/en-gb': {
    label: 'United Kingdom',
    language: 'EN',
    country: 'GB',
    currency: 'GBP',
  },
  default: {
    label: 'United States',
    language: 'EN',
    country: 'US',
    currency: 'USD',
  },
};